<template>
  <div class="text-search-wrap" :class="{ dark }">
    <input
      type="text"
      v-model="tmpText"
      :placeholder="placeholder"
      @keyup.enter="onSearch"
    />
    <div class="control-wrap">
      <div
        class="clean"
        :style="{ visibility: tmpText && tmpText.length > 0 ? 'visible' : 'hidden' }"
        @click="onClean"
      >
        <img src="@/assets/icons/clear.svg" alt="" />
      </div>
      <div class="search" @click="onSearch">
        <img src="@/assets/icons/search.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextSearchBar',
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    tmpText: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    init() {
      this.tmpText = this.value
    },
    onClean() {
      // this.tmpText = ''
      this.$emit('clean')
    },
    onSearch() {
      this.$emit('search', this.tmpText)
    }
  },
  // mounted() {
  //   this.init()
  // },
  watch: {
    value() {
      this.init()
    },
    // 資料多的時候會很頓
    tmpText(nVal) {
      if (!nVal) {
        this.onSearch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.text-search-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: px2rem(36);
  width: 100%;
  font-size: 1.5rem;
  // background-color: currentColor;

  $OutGap: 12;
  $SearchW: 24;
  $CleanW: 12;

  input {
    // border: 1px solid #9D9D9D;
    border-radius: 0.5rem;
    outline: none;
    padding-top: px2rem(6);
    padding-right: calc(px2rem($SearchW) + px2rem(24) + px2rem($CleanW));
    padding-bottom: px2rem(6);
    padding-left: px2rem($OutGap);
    height: 100%;
    width: 100%;
    font-size: 1rem;
  }

  .control-wrap {
    position: relative;
    display: flex;
    align-items: center;

    .clean {
      position: absolute;
      right: px2rem($SearchW + $CleanW);
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      width: px2rem($CleanW);
      height: px2rem($CleanW);
      cursor: pointer;

      img {
        width: 1rem;
        height: 1rem;
        @include filter_placeholder;
      }

      &:hover {
        img {
          @include filter_black;
        }
      }
    }
    .search {
      position: absolute;
      right: px2rem($OutGap);
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        @include filter_placeholder;
      }
      &:hover {
        img {
          @include filter_black;
        }
      }
    }
  }

  &.dark {
    input {
      color: $color_FFF;
      background-color: $color_39425D;
    }
  }
}
</style>
