<template>
  <div class="classify-select-wrap">
    <div v-if="title" class="title-wrap">
      <span>{{ title }}</span>
    </div>
    <el-tooltip
      popper-class="el-tooltip"
      effect="dark"
      v-delTabIndex
      :visible-arrow="false"
      :disabled="disabled"
      :content="labelText"
      :placement="tooltipPlacement"
    >
      <button
        :class="[
          'label-wrap',
          effect,
          {
            active: showDropdown,
            'has-multiple-select': multiple && this.value.length > 0
          }
        ]"
        :disabled="disabled"
        @click="onOpenDropdown"
      >
        <div :class="['label-content', effect]">
          <!-- 單選才能顯示 ICON -->
          <img
            v-if="!multiple && valueOption && valueOption.img"
            class=""
            :src="valueOption.img"
            alt=""
          />
          <span
            :class="{
              placeholder: labelText === placeholder
            }"
          >
            {{ labelText }}
          </span>
        </div>
        <img
          class="dropdown-icon"
          :class="{ active: showDropdown }"
          src="@/assets/icons/Drop.svg"
          alt=""
        />
      </button>
    </el-tooltip>
    <div
      v-if="showDropdown"
      class="dropdown-wrap"
      v-click-outside="onClickOutside"
    >
      <TextSearchBar
        class="search-wrap"
        v-model="filterText"
        :placeholder="$t('search')"
        :dark="true"
        @clean="filterText = ''"
        @search="() => {}"
        @input="onInputFilterText"
      />
      <!-- 全選 -->
      <button
        v-if="multiple"
        :disabled="options.length <= 0"
        class="select-all option-wrap"
        @click="onSelectAll"
      >
        <img
          v-if="isSelectedAll"
          class="checkbox"
          src="@/assets/icons/checkbox-yellow-checked.svg"
          alt=""
        />
        <img
          class="checkbox"
          v-else
          src="@/assets/icons/checkbox-white-square.svg"
          alt=""
        />
        <span class="text">{{ $t('select_all') }}</span>
      </button>
      <div class="options-wrap">
        <button
          v-for="(o, idx) in options"
          :key="`${o.label}_${o.value}_${idx}`"
          class="option-wrap"
          :disabled="o.classify || o.disabled === true"
          :class="{ classify: o.classify }"
          @click="onSelect(o)"
        >
          <!-- {{ JSON.stringify(o) }} -->
          <template v-if="multiple && !o.classify">
            <img
              v-if="isSelected(o)"
              class="checkbox"
              src="@/assets/icons/checkbox-yellow-checked.svg"
              alt=""
            />
            <img
              class="checkbox"
              v-else
              src="@/assets/icons/checkbox-white-square.svg"
              alt=""
            />
          </template>
          <img
            v-if="o.icon"
            class="value-icon"
            :src="require(`@/assets/icons/${o.icon}`)"
            alt=""
          />
          <span class="text" :class="{ classify: o.classify }">
            {{ o.label }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import TextSearchBar from '@/components/tools/TextSearchBar.vue'

export default {
  name: 'ClassifySelect',
  components: { TextSearchBar },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    // title
    title: {
      type: String,
      default: null,
    },

    // label
    disabled: {
      type: Boolean,
      default: null
    },
    // placeholder 與 funName 二選一
    placeholder: {
      type: String,
      default: null
    },
    // funName 與 placeholder 二選一, funName 為預設
    funName: {
      type: String,
      default: null
    },
    effect: {
      type: String,
      default: 'light', // 'dark'
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom', // 預設 'bottom'
    },

    // value
    value: {
      require: true,
      type: [Number, String, Array],
      default: null
    },

    // options
    multiple: {
      type: Boolean,
      default: true
    },
    options: {
      require: true,
      type: Array,
      default() {
        return [
          // {
          //   classify: Boolean,
          //   label: String,
          //   value: [String, Number, Array],
          //   disabled: Boolean,
          //   icon: require('...') => optional
          // }
        ]
      }
    },
  },
  data() {
    return {
      showDropdown: false,
      filterText: null,
    }
  },
  computed: {
    valueOption() {
      if (this.multiple) return null

      const o = this.options.find((_o) => _o.icon && _o.value === this.value)
      // console.log(`[valueOption] o:`, o)
      return {
        label: o ? o.label : '',
        img: o ? require(`@/assets/icons/${o.icon}`) : null
      }
    },
    labelText() {
      if (this.multiple) {
        if (this.value.length > 0) {
          const selectedList = this.value.map((_v) =>
            this.options
              .filter((_o) => !_o.classify)
              .find((_o) => _o.value === _v)
          )
          return selectedList.map((_o) => _o.label).join('、')
        }
      } else {
        if (this.value) {
          const selected = this.options
            .filter((_o) => !_o.classify)
            .find((_o) => _o.value === this.value)
          if (selected) return selected.label
        }
      }

      // console.log(`[labelText] placeholder:`, this.placeholder)
      // console.log(`[labelText] funName:`, this.funName)
      return this.placeholder || this.funName
    },
    isSelectedAll() {
      const full = this.options
        .filter((_o) => !_o.classify)
        .map((_o) => _o.value)

      return full.length > 0 && full.length === this.value.length
    },
    hasMultipleSelect() {
      return false
    }
  },
  watch: {
    showDropdown() {
      if (!this.showDropdown) {
        this.filterText = ''
        this.$emit('getFilterText', this.filterText)
      }
    }
  },
  methods: {
    isSelected(option) {
      return this.multiple
        ? this.value.includes(option.value)
        : this.value === option.value
    },

    // 事件
    onOpenDropdown() {
      this.showDropdown = !this.showDropdown
    },
    onClickOutside() {
      this.showDropdown = false
    },
    onInputFilterText(/*event*/) {
      this.$emit('getFilterText', this.filterText)
    },
    onSelectAll() {
      // 只有多選才執行該函式
      if (!this.isSelectedAll) {
        const tmpVal = this.options
          .filter((_o) => !_o.classify)
          .map((_o) => _o.value)

        this.$emit('input', tmpVal)
      } else {
        this.$emit('input', [])
      }
    },
    onSelect(option) {
      // 多選模式下不關閉下拉選單, 單選才關閉
      const { value } = option

      // 多選
      if (this.multiple) {
        let tmpValue = [...this.value]
        let valueIdx = this.value.indexOf(value)

        if (valueIdx < 0) {
          tmpValue.push(value)
        } else {
          tmpValue.splice(valueIdx, 1)
        }

        this.$emit('input', tmpValue)
      }
      // 單選
      else {
        this.$emit('input', option.value)
        this.onClickOutside()
      }
    }
  },
  mounted() {
    // console.log(`[ClassifySelect] options:`, this.options)
  }
}
</script>

<style lang="scss" scoped>
$FontSize: 1rem;
$ElementH: px2rem(36); // calc($FontSize * 1.5);

* {
  box-sizing: border-box;
  // user-select: none;
}

.classify-select-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

.title-wrap {
  margin-bottom: 0.25rem;

  span {
    font-size: 0.875rem; // 14px
    line-height: 1.5;
    color: $color_FFF;
  }
}

.label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: px2rem(2) solid transparent;
  // border: px2rem(4) solid #f00; // DEBUG
  padding: px2rem(6) px2rem(12);
  width: 100%;
  height: $ElementH;
  background-color: $color_FFF;
  overflow: hidden;

  &:disabled {
    @include disabled;
  }

  &.has-multiple-select {
    border-color: $color_FFF;
  }

  &:not(.light).active {
    background-color: $color_4A5C78;
  }

  .label-content {
    display: flex;
    align-items: center;
    width: calc(100% - 1.2rem);

    img {
      width: 1rem;
      height: 1rem;
    }
    span {
      width: 100%;
      color: $color_black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.placeholder {
        color: $color_placeholder;
      }
    }
    img + span {
      margin-left: px2rem(6);
    }

    &.light {
      img {
        width: 1rem;
        height: 1rem;
        @include filter_black;
      }
    }
  }

  .dropdown-icon {
    justify-self: flex-end;
    width: 1rem;
    height: 1rem;
    @include filter_black;

    transform: rotate(0deg);
    transition: $AnimateSec cubic-bezier(0.75, 0.05, 0.07, 1.05);
    &.active {
      transform: rotate(180deg);
    }
  }

  &.dark {
    background-color: $color_39425D;
    .label-content {
      span:not(.placeholder) {
        color: $color_FFF;
      }
    }
    .dropdown-icon {
      filter: unset;
    }
  }
}

.dropdown-wrap {
  position: absolute;
  border-radius: 0.5rem;
  border: 1px solid $color_4A5C78;
  padding-top: px2rem(12);
  padding-bottom: px2rem(6);
  width: 100%;
  max-height: 70vh;
  background-color: $color_151B35;
  z-index: 2;

  .search-wrap {
    margin-bottom: px2rem(6);
    padding: 0 px2rem(12);
  }

  .option-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: px2rem(6);
    padding-bottom: px2rem(6);
    padding-left: px2rem(12);
    padding-right: px2rem(12);
    width: 100%;

    img {
      width: 1rem;
      height: 1rem;
    }

    .checkbox {}
    .value-icon {}
    .text {
      color: $color_FFF;
      &.classify {
        @include font_bold;
        color: $color_FFE99F;
      }
    }

    .checkbox + .value-icon,
    .checkbox + .text,
    .value-icon + .text {
      margin-left: px2rem(6);
    }

    &:not(:disabled):not(.classify):hover {
      background-color: $color_6E7D93_20;
    }
    &:disabled:not(.classify) {
      @include disabled;
    }
  }

  .select-all {
    // &.option-wrap {}
    margin-bottom: px2rem(6);
  }

  .options-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: flex-start;
    border-top: 1px solid $color_divider;
    max-height: 40vh;
    overflow-y: scroll;
  }
}
</style>
